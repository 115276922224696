.spinner,
.spinner:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
}

.spinner {
  animation: spin 1.1s infinite linear;
  -webkit-animation: spin 1.1s infinite linear;
  border-bottom: 1em solid #0067c5;
  border-left: 1em solid #00539f;
  border-right: 1em solid #0067c5;
  border-top: 1em solid #0067c5;
  font-size: 10px;
  margin: 60px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}