#testimonials.carousel {
  .carousel-inner {
    background-color: #fff;
    height: 480px;

    @media (min-width: 992px) {
      height: 420px;
    }
  }

  .carousel-item {
    height: 100%;
  }

  .carousel-caption {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .carousel-caption-inner {
    color: #2d2d2d;
    font-family: "PT Serif",serif;
    font-size: 1rem;
    line-height: 1.7;
    text-align: left;

    @media (min-width: 992px) {
      font-size: 1.1rem;
      max-width: 800px;
    }
  }

  .carousel-caption-author {
    font-size: 1.1rem;
    font-weight: 700;
    margin-top: 5px;
    text-align: right;

    @media (min-width: 992px) {
      font-size: 1.3rem;
    }   
  }

  .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230067c5%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e');
  }

  .carousel-control-next-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23230067c5%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  }

  .carousel-control-prev-icon, 
  .carousel-control-next-icon {
    height: 2rem;
    width: 2rem;

    @media (min-width: 992px) {
      height: 3rem;
      width: 3rem;
    }   
  }
}