.team {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &-list {
    padding: 2rem;
  
    @media (min-width: 992px) {
      padding: 2rem 6rem 3rem;
    }
  }
}

