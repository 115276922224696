.blogs {
  &-preview {
    background: #eee;
    display: flex;
    justify-content: center;
    padding: 0;
  
    @media (min-width: 992px) {
      padding: 2.5rem 4rem;
    }
  }

  &-col {
    background: #fff;
    border: 1px solid #ccc;
    padding: 2rem 1.6rem;
    width: 100%; 
    
    @media (min-width: 992px) {
      width: 950px; 
    }
  }

  &-title {
    color: #00539f;
    font-size: 1.5em;
    font-weight: 700;
    margin: 0 0 1.3rem;
  }
}