.blog-item {
  border: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  max-width: 950px;
  padding: 12px 16px 12px;

  &-col {
    &.img-col {
      width: 100%;

      @media (min-width: 992px) {
        width: 27.8%;
      }
    }

    &.info-col {
      margin-top: 8px;
      width: 100%;

      @media (min-width: 992px) {
        margin-top: 0;
        width: 70%;
      }     
    }
  }

  &-title {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.1;
    margin: 0 0 3px;

    a {
      color: #00539f;
      text-decoration: none;

      &:hover {
        color: #003f79;
      }
    }
  }

  &-date {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 5px;  
  }

  &-post {
    font-size: 15px;
    line-height: 1.5;

    a {
      color: #00539f;

      &:hover {
        color: #003f79;
      }
    }
  }
}