.about {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;

  @media (min-width: 992px) {
    padding: 4rem 7rem;
  }

  &-col {
    width: 100%;

    @media (min-width: 992px) {
      width: 50%;
    }

    &:last-child {
      padding: 0;

      @media (min-width: 992px) {
        padding: 0 3.3rem;
      }
    }
  }

  &-hdr {
    color: #2d2d2d;
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: .4px;
    line-height: 1.2;
    margin-top: 1.3rem;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 992px) {
      margin-top: 1rem;
      text-align: left;
    }   
    
    &::after {
      border-top: 1px solid #000;
      content: '';
      display: block;
      margin: 1.1rem auto;
      width: 65px; 

      @media (min-width: 992px) {
        margin: 1.1rem 0 2.2rem;
      }
    }
  }

  p {
    text-align: center;

    @media (min-width: 992px) {
      display: inline-block;
      margin: .5rem 0 1.75rem;
      text-align: left;
    }   
  }

  .btn-primary.about-btn {
    display: flex;
    margin-top: 8px;
    text-align: center;

    @media (min-width: 992px) {
      display: inline-block;
      margin: .75rem 0 2.2rem;
    }    
  }
}