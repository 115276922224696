.service {
  &-content {
    padding: 2rem;

    @media (min-width: 992px) {
      padding: 4rem 10rem;
    }

    @media (min-width: 1400px) {
      padding: 4rem 15rem;
    }
  }

  &-body {
    p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    h2 {
      color: #00539f;
      font-size: 1.5rem;
      font-weight: 700;

      &:not(:first-child) {
        margin-top: 1.75rem;
      }
    }

    ul {
      margin-bottom: 0;
      padding-left: 1.4rem;
    }
  }

  .btn-return {
    margin-top: 1.5rem;
  }
}