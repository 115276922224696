.team-card.card {
  border: 1px solid #ccc;
  border-radius: 2px;
  height: auto;
  margin: 5px;
  padding: 1.2rem;
  width: 305px;

  .card-body {
    padding: 0;
  }

  .card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    a {
      color: #00539f;
      text-decoration: none;

      &:hover {
        color: #003f79;
      }
    }
  }

  .card-subtitle {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  .card-phone {
    color: #2d2d2d;
    display: block;
    font-family: 'Roboto',sans-serif;
    line-height: 1em;
    margin-bottom: 0.25rem;
    text-decoration: none;
    width: fit-content;

    &:hover {
      color: #2d2d2d;
    }
  }

  .card-email {
    color: #00539f;
    display: block;
    font-family: 'Roboto',sans-serif;
    line-height: 1em;
    width: fit-content;

    &:hover {
      color: #002b53;
    }
  }
}